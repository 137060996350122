import React from 'react'

const NotFoundPage = () => {
    return (
        <div className="min-h-screen bg-gray-50">
            <h1>Page not found</h1>
        </div>
    )
}

export default NotFoundPage